import React, { useState } from "react";
import ceo from "../../assets/ceo.jpeg";
import { SectionWrapper } from "../../hoc";

const CeoSection = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalToggle = () => {
    setIsModalOpen((prev) => !prev);
  };

  return (
    <div className="ceo-section">
      <div>
        <p className="ceo-section__title">ABOUT THE CEO</p>
        <p className="ceo-section__sub-title">
          About Abayomi Emmanuel Adegboyega – Founder and CEO, Jaris
          Computational Biology Centre
        </p>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-1">
        <div>
          <img className="ceo-section__img mt-5" src={ceo} alt="CEO" />
        </div>

        <div>
          <div className="flex flex-col">
            <div className="ceo-section__para-container">
              <p>
                Abayomi Emmanuel Adegboyega is a visionary leader, accomplished
                scientist, and the pioneering founder and CEO of Jaris
                Computational Biology Centre (JCBC), Nigeria’s premier research
                institution dedicated to advancing computational and
                structure-based drug design. Under his leadership, JCBC has
                become a beacon of innovation, bridging the gap between
                traditional biomedical research and cutting-edge computational
                methodologies to tackle some of the most pressing health
                challenges of our time, including COVID-19, cancer, diabetes,
                malaria, and neurodegenerative diseases.{" "}
                <span
                  className="text-blue-700 cursor-pointer hover:underline"
                  onClick={handleModalToggle}
                >
                  Read more...
                </span>
              </p>

              {isModalOpen && (
                <div
                  className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
                  onClick={handleModalToggle}
                >
                  <div
                    className="relative bg-white rounded-lg shadow dark:bg-gray-700 w-full max-w-2xl"
                    onClick={(e) => e.stopPropagation()}
                  >
                    {/* Modal header */}
                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                      <h3 className="text-xl font-semibold text-gray-900">
                        About the CEO
                      </h3>
                      <button
                        type="button"
                        className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
                        onClick={handleModalToggle}
                      >
                        <svg
                          className="w-3 h-3"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 14 14"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                          />
                        </svg>
                        <span className="sr-only">Close modal</span>
                      </button>
                    </div>

                    {/* Modal body */}
                    <div className="p-4 md:p-5 space-y-4 h-[500px] overflow-y-auto">
                      <p className="text-base leading-relaxed text-gray-700">
                        Abayomi Emmanuel Adegboyega is a visionary leader,
                        accomplished scientist, and the pioneering founder and
                        CEO of Jaris Computational Biology Centre (JCBC),
                        Nigeria’s premier research institution dedicated to
                        advancing computational and structure-based drug design.
                        Under his leadership, JCBC has become a beacon of
                        innovation, bridging the gap between traditional
                        biomedical research and cutting-edge computational
                        methodologies to tackle some of the most pressing health
                        challenges of our time, including COVID-19, cancer,
                        diabetes, malaria, and neurodegenerative diseases.
                      </p>
                      <p className="text-base leading-relaxed text-gray-700">
                        Established in 2020, JCBC was born out of Mr.
                        Adegboyega’s passion for harnessing the power of
                        computational biology to accelerate drug discovery and
                        development across Africa. Recognizing the limitations
                        of conventional drug design methods, he founded JCBC to
                        provide African researchers with the tools and skills
                        needed to integrate computer-aided approaches into their
                        work. The institution has since trained over 1,000
                        researchers from across Nigeria and beyond, empowering
                        them to utilize computational tools and methodologies to
                        expedite drug discovery processes.
                      </p>
                      <p className="text-base leading-relaxed text-gray-700">
                        Mr. Adegboyega envisions JCBC as a global leader in
                        computational biology, driving cutting-edge research
                        that addresses Africa’s unique health challenges while
                        contributing to international scientific advancements.
                        His goal is to establish JCBC as a hub for innovation,
                        where African scientists can collaborate with global
                        experts to develop novel therapies and contribute to
                        breakthroughs in medicine. He is committed to fostering
                        an environment that nurtures young talent, encourages
                        cross-disciplinary collaboration, and transforms JCBC
                        into a powerhouse for scientific discovery.
                      </p>
                      <p className="text-base leading-relaxed text-gray-700">
                        His leadership at JCBC extends beyond training. He has
                        fostered collaborations with over 50 scientists
                        globally, expanding the Centre’s research capacity and
                        enabling breakthrough discoveries. In addition to
                        spearheading JCBC, Mr. Adegboyega is an accomplished
                        researcher with over 40 peer-reviewed publications and
                        more than 500 citations. His work focuses on utilizing
                        computational tools to unlock the therapeutic potential
                        of natural plant compounds, developing novel treatments
                        for diseases of public health significance. His
                        contributions to the scientific community have been
                        celebrated by major media outlets such as The Punch,
                        Guardian and Vanguard, further cementing his reputation
                        as an influential leader in computational drug
                        discovery.
                      </p>
                      <p className="text-base leading-relaxed text-gray-700">
                        A recipient of prestigious awards such as the Gilham
                        PI4D Fellow Award and the Lynn Fellowship at Purdue
                        University, USA, Mr. Adegboyega continues to shape the
                        future of drug design and structural biology. His
                        commitment to mentorship, collaboration, and
                        groundbreaking research positions him as a trailblazer
                        in his field, driving transformative change in global
                        health through innovation and scientific excellence.
                      </p>
                    </div>

                    {/* Modal footer */}
                    <div className="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
                      <button
                        type="button"
                        className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-gray-700 focus:z-10 focus:ring-4 focus:ring-gray-100"
                        onClick={handleModalToggle}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionWrapper(CeoSection);
